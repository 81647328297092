import { useRoutes } from 'react-router-dom';

// routes
// import AuthenticationRoutes from './AuthenticationRoutes';
import LoginRoutes from './LoginRoutes';
import { AuthenticationRoutes, CreateMemorialRoutes, PreviewMemorialRoutes } from './AuthenticationRoutes';
import { MainRoutes, PublicMemorialPreview, TyCAndPdP } from './MainRoutes';
import DownloadAppRoute from './individualRoutes/DownloadAppRoute';
import NotFoundRoute from './individualRoutes/NotFoundRoute';
// import useAuth from 'hooks/useAuth';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const authRoutes = [AuthenticationRoutes, CreateMemorialRoutes, PreviewMemorialRoutes];
    const guestRoutes = [MainRoutes, DownloadAppRoute, LoginRoutes, TyCAndPdP, PublicMemorialPreview];
    const notFoundRoute = NotFoundRoute;

    // const routes = isLoggedIn ? authRoutes : guestRoutes;

    return useRoutes([...guestRoutes, ...authRoutes, notFoundRoute]);
}
