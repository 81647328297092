import { Box, Grid } from '@mui/material';
import Text from 'ui-component/Text/Text';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MemorialIcon } from '../../../../assets/icons/svg/memorials_blue_icon.svg';

interface TypeCardProp {
    label: string;
    icon: React.ReactElement;
    onClick: () => void;
    fontFamily?: 'Inter' | 'Merriweather';
    fontWeight?: number;
    fontPreset?: string;
    height?: number;
    padding?: number;
    description?: string;
}

export const TypeCard = ({
    label,
    icon,
    fontFamily = 'Inter',
    fontWeight = 500,
    fontPreset = 'titleLarge',
    height,
    padding = 5,
    onClick,
    description
}: TypeCardProp) => {
    return (
        <Grid
            item
            display={'flex'}
            flexDirection={'column'}
            textAlign={'center'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
                backgroundColor: '#ECF1FA',
                width: '90%',
                padding: padding,
                ...(height && { height }),
                borderRadius: '9px',
                cursor: 'pointer'
            }}
            xs={12}
            sm={5}
            mb={2}
            onClick={onClick}
        >
            {icon}
            <Text preset={fontPreset} fontFamily={fontFamily} fontWeight={fontWeight} sx={{ xs: { mt: 1.5 } }}>
                {label}
            </Text>
            {description && (
                <Text preset={'titleSmall'} fontWeight={500} sx={{ xs: { mt: 1 } }}>
                    {description}
                </Text>
            )}
        </Grid>
    );
};

const SelectMemorialType = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const OPTIONS = [
        // { label: t('memorials.create.legacyLabel'), icon: <LegacyIcon />, navigation: '/app/memorial/create' },
        { label: t('memorials.create.memorialLabel'), icon: <MemorialIcon />, navigation: '/app/memorial/create' }
    ];

    return (
        <Box>
            <Grid container display={'flex'} justifyContent={'space-around'}>
                {OPTIONS.map((option) => (
                    <TypeCard label={option.label} icon={option.icon} key={option.label} onClick={() => navigate(option.navigation)} />
                ))}
            </Grid>
        </Box>
    );
};

export default SelectMemorialType;
