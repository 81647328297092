import { createApi } from '@reduxjs/toolkit/query/react';
import { httBaseQueryWithRetry } from 'services/rtk/baseQuery';

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: httBaseQueryWithRetry,
    endpoints: (builder) => ({
        checkToken: builder.query<any, void>({
            query: () => ({
                url: '/core/auth/check-token',
                method: 'get'
            })
        })
    })
});

export const { useLazyCheckTokenQuery, useCheckTokenQuery } = authApi;
