import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import useConfig from 'hooks/useConfig';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const { REACT_APP_FIREBASE_ASSETS_BASE_URL } = useConfig();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const { t } = useTranslation();

    return (
        <Stack width="100vw" height="100vh" alignItems="center" justifyContent="center">
            <Helmet>
                {process.env.REACT_APP_IS_PROD_ENV === 'false' && <meta name="robots" content="noindex, nofollow" />}
                <link rel="canonical" href="https://besoul.app/app/memorial/feed" />
                <meta name="title" content={t('common.seo.title')} />
                <meta name="description" content={t('common.seo.description')} />
                <title>{t('common.seo.title')}</title>
            </Helmet>
            <Box
                component="img"
                height={{ xs: 'auto', sm: '100%', md: 'auto' }}
                width={{ xs: '100%', sm: 'auto', md: '100%' }}
                margin="auto"
                src={`${REACT_APP_FIREBASE_ASSETS_BASE_URL}/${isDesktop ? '404%20error%20BS_2.2' : '404-error-BS-2.4'}.webp`}
            />
        </Stack>
    );
};

export default NotFound;
