import { useGetMemorialByIdQuery } from 'api/memorial.service';
import useAuth from 'hooks/useAuth';
import { createContext, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';

export type MemorialContextType = {
    openModal: boolean;
    handleOpenModal: (modalForm: MODAL_FORM) => void;
    handleCloseModal: (modalForm: MODAL_FORM) => void;
    updateFormValues: (formValues: any) => void;
    modalFormOpened?: MODAL_FORM;
    formValue?: any;
};

export interface InitialMemorialContextProps {
    openModal: boolean;
    modalFormOpened?: MODAL_FORM;
    formValue?: any;
}

export const MemorialContext = createContext<MemorialContextType | null>(null);

export enum MODAL_FORM {
    NAME = 'NAME',
    BIRTHDATE = 'BIRTHDATE',
    DEATHDATE = 'DEATHDATE',
    STORY = 'STORY',
    PARTING_WORDS = 'PARTING_WORDS'
}

const initialState: InitialMemorialContextProps = {
    openModal: false,
    modalFormOpened: MODAL_FORM.NAME,
    formValue: {}
};

export enum ACTIONS {
    INIT = 'INIT',
    OPEN_MODAL = 'OPEN_MODAL',
    CLOSE_MODAL = 'CLOSE_MODAL',
    UPDATE = 'UPDATE'
}

interface AccountReducerActionProps {
    type: ACTIONS;
    payload?: InitialMemorialContextProps;
}

const memorialReducer = (state = initialState, action: AccountReducerActionProps) => {
    switch (action.type) {
        case ACTIONS.INIT: {
            const { formValue } = action.payload!;
            return {
                ...state,
                formValue
            };
        }
        case ACTIONS.OPEN_MODAL: {
            return {
                ...state,
                openModal: true,
                modalFormOpened: action.payload?.modalFormOpened
            };
        }
        case ACTIONS.CLOSE_MODAL: {
            return {
                ...state,
                openModal: false,
                modalFormOpened: action.payload?.modalFormOpened
            };
        }
        case ACTIONS.UPDATE: {
            const { formValue } = action.payload!;
            return {
                ...state,
                formValue
            };
        }
        default: {
            return { ...state };
        }
    }
};

const mapData = (memorialData: any) => {
    return {
        _id: memorialData._id,
        name: memorialData.name,
        type: memorialData.type,
        originalProfilePhoto: memorialData.profilePhoto,
        profilePhoto: memorialData.profilePhoto,
        originalFiles: memorialData.files,
        files: memorialData.files,
        story: memorialData.story,
        partingWords: memorialData.phrase,
        birthDate: memorialData.birthDate,
        deathDate: memorialData.deathDate,
        placeOfBirth: memorialData.placeOfBirth,
        placeOfDeath: memorialData.placeOfDeath
    };
};

export const MemorialProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(memorialReducer, initialState);
    const { isLoggedIn } = useAuth();
    const { memorialId } = useParams();
    const { data: memorialData } = useGetMemorialByIdQuery({ memorialId, isLoggedIn }, { refetchOnMountOrArgChange: true });

    useEffect(() => {
        if (memorialData) {
            dispatch({
                type: ACTIONS.INIT,
                payload: {
                    ...state,
                    formValue: mapData(memorialData)
                }
            });
        }
    }, [memorialData]);

    const handleOpenModal = (modalForm: MODAL_FORM) => {
        dispatch({
            type: ACTIONS.OPEN_MODAL,
            payload: {
                openModal: true,
                modalFormOpened: modalForm
            }
        });
    };

    const handleCloseModal = (modalForm: MODAL_FORM) => {
        dispatch({
            type: ACTIONS.CLOSE_MODAL,
            payload: {
                openModal: false,
                modalFormOpened: modalForm
            }
        });
    };

    const updateFormValues = (formValues: any) => {
        dispatch({
            type: ACTIONS.UPDATE,
            payload: {
                ...state,
                formValue: { ...state.formValue, ...formValues }
            }
        });
    };

    return (
        <MemorialContext.Provider
            value={{
                ...state,
                handleOpenModal,
                handleCloseModal,
                updateFormValues
            }}
        >
            {children}
        </MemorialContext.Provider>
    );
};
