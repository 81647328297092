const plans = {
    seo: {
        title: 'Planes y precios | Be Soul',
        description: `Desbloquea las ventajas premium con los planes de suscripción de Be Soul. Comparta recuerdos e historias y cree un legado digital significativo. Elige tu plan y empieza hoy mismo`
    },
    banner: {
        title: {
            '1': 'Nuestros',
            '2': 'Planes'
        },
        subtitle: 'Preserva tu legado en Be Soul'
    },
    title: 'Planes',
    plans: {
        month: ' / Mes',
        yearly: 'anual',
        basic: {
            name: 'Básico',
            features: {
                '1': '10 Legados',
                '2': '20 Accesos a Legados',
                '3': '3,000 fotos',
                '4': '30 min de video',
                '5': '2,076 min de audio',
                '6': 'Descargar multimedia de Legados'
            }
        },
        standard: {
            name: 'Estándar',
            features: {
                '1': '25 Legados',
                '2': '50 Accesos a Legados',
                '3': '12,000 fotos',
                '4': '122 min de video',
                '5': '8,302 min de audio',
                '6': 'Descargar multimedia de Legados'
            }
        },
        premium: {
            name: 'Premium',
            features: {
                '1': '100 Legados',
                '2': '100 Accesos a Legados',
                '3': '384,000 fotos',
                '4': '3,904 min de video',
                '5': '256,672 min de audio',
                '6': 'Descargar multimedia de Legados'
            }
        },
        recommended: 'Recomendado'
    },
    warranty:
        'Todos los planes tienen un 1 mes de prueba. No se hará ningún cargo durante ese período. El plan se renueva automáticamente, al menos que lo canceles hasta un día antes de su finalización',
    TyC: { 1: 'Para mayor información puede consultar los ', 2: 'Términos y Condiciones', 3: ' de Be Soul' },
    button: 'Comienza ahora',
    tooltip: {
        1: '1 Megabyte (MB) = 1.000.000 bytes; 1 Gigabyte (GB) = 1.000.000.000.000 bytes; 1 Terabyte (TB) = 1.000.000.000.000.000 bytes',
        2: 'El almacenamiento es total y la cantidad en número indicada para cada formato de contenido audiovisual es referencial, pudiendo variar en función del modelo de cámara, resolución seleccionada, configuración de compresión y contenido'
    }
};

export default plans;
