import { createSlice } from '@reduxjs/toolkit';

export interface UserInfo {
    id: string;
    name: string;
    lastName: string;
    email: string;
    phone: Phone;
    badgets: any[];
    alive: boolean;
    status: string;
    subscriptionsRecord: any[];
    accountType: string;
    familyLength: number;
    subscriptionRecord: SubscriptionRecord;
}

export interface Phone {
    cca2: string;
    code: string;
    number: string;
    verify: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface SubscriptionRecord {}

export interface InitialState {
    userInfo: UserInfo;
}

const initialState: InitialState = {
    userInfo: {
        id: '',
        name: '',
        lastName: '',
        email: '',
        phone: {
            cca2: '',
            code: '',
            number: '',
            verify: false,
            createdAt: new Date(),
            updatedAt: new Date()
        },
        badgets: [],
        alive: true,
        status: 'Active',
        subscriptionsRecord: [],
        accountType: 'Personal',
        familyLength: 0,
        subscriptionRecord: {}
    }
};

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInfo(state, action) {
            state.userInfo = { ...state.userInfo, ...action.payload };
        },
        resetUser(state) {
            state.userInfo = initialState.userInfo;
        }
    }
});

export default user.reducer;
export const { setUserInfo, resetUser } = user.actions;
