const memorials = {
    seo: {
        title: 'Honrar a los seres queridos | Be Soul Memorials',
        description:
            'Explore nuestra Sección de Conmemoraciones, un espacio compasivo para los recuerdos más queridos de personas y mascotas. Cree memoriales digitales perdurables con fotos, historias y homenajes.'
    },
    banner: {
        title: 'Memoriales',
        subtitle: 'Comparte la historia de la vida de tus seres amados',
        label: 'Próximamente'
    },
    commemorate: {
        title: 'Conmemora a quienes ya no están:',
        item1: 'Cuenta la historia de tu ser querido o mascota',
        item2: 'Obtén ayuda de la IA para armar tus conmemoraciones',
        item3: 'Comparte recuerdos a través de fotos y videos',
        item4: 'Los amigos y familiares pueden dejar sus dedicatorias a través de texto, fotos y videos'
    },
    howItWorks: {
        title: '¿Cómo funciona?',
        item1: 'Selecciona a esa persona o mascota especial en tu vida cuya memoria quieras honrar',
        item2: 'Cuenta su historia, utilizando tus propias palabras o con la ayuda de la IA, y comparte su fotografía',
        item3: 'Publica su memorial para que sus allegados puedan expresar sus condolencias y compartir recuerdos'
    },
    button: 'Descarga Be Soul ',
    create: {
        title: 'Crear memorial',
        selectTypeTitle: '¿Qué quieres crear?',
        legacyLabel: 'Legados',
        memorialLabel: 'Memoriales',
        lovedOneLabel: 'Un ser querido',
        petLabel: 'Una mascota',
        forWhoTitle: '¿Para quién es este memorial?',
        howTitle: '¿Cómo quieres crear este memorial?',
        assistanceWrite: 'Te ayudamos con tu escritura',
        noAssistanceWrite: 'Sin asistencia de escritura',
        assistanceWriteDescription: 'Le ayudamos a redactar su memorial con preguntas guiadas e inteligencia artificial',
        noAssistanceWriteDescription:
            'Rellene un formulario tradicional y cree su monumento conmemorativo según sus preferencias sin ayuda',
        form: {
            nameLabel: '¿Cuál fue el nombre de la persona?',
            petNameLabel: '¿Cuál fue el nombre de tu mascota?',
            namePlaceHolder: 'Nombre y apellido',
            petNamePlaceHolder: 'Nombre',
            deathLabel: '¿Dónde murió?',
            deathPlaceHolder: 'Estado/Ciudad, País',
            birthLabel: '¿Dónde nació?',
            birthLabel2: '¿Cúando nació?',
            storyLabel: 'Cuéntanos su historia',
            storyPlaceholder: 'Comienza escribiendo aquí',
            partingWordsLabel: 'Escribe unas palabras de despedida en su honor',
            partingWordsPlaceholder: 'Escribe algo',
            nextLabel: 'Siguiente',
            doneLabel: 'Listo',
            prevLabel: 'Anterior'
        }
    },
    edit: {
        editLabel: 'Editar memorial'
    },
    draft: {
        memorialCardTitle: 'Memorial',
        galleryCardTitle: 'Galería',
        uploadPhotoLabel: 'Subir una foto:',
        inMemoryLabel: 'En memoria de: ',
        nameLabel: 'Nombre y apellido',
        datePlaceBirthLabel: 'Fecha y lugar de nacimiento',
        datePlaceDeathLabel: 'Fecha y lugar de muerte',
        partingWordsLabel: 'Últimas palabras:',
        storyLabel: 'Su historia:',
        parttingWordsEmptyState:
            'Inclúyele una frase significativa, un poema, una cita o cualquier otro mensaje que refleje la vida o personalidad de tu ser querido',
        editButton: 'Editar',
        storyEmptyState: 'Cuente sus logros, describa la esencia de su ser querido y la razón por la que se le echará tanto de menos',
        editStoryButton: 'Editar historia',
        previewButton: 'Vista previa',
        postButton: 'Postear'
    },
    delete: {
        deleteLabel: 'Eliminar memorial',
        confirmDeleteLabel: '¿Quieres eliminar este memorial?',
        delete: 'Eliminar',
        keepMemorial: 'Conservar memorial'
    },
    preview: {
        header: {
            label: 'Vista previa'
        }
    },
    guestBook: {
        drawerHeader: 'Libro de visitas'
    },
    dedications: {
        drawerHeader: 'Agregar dedicatoria',
        publishLabel: 'Publicar',
        dedicationsLabel: 'Dedicatorias',
        deleteDedicationLabel: 'Eliminar',
        dedicationPlaceholder: 'Escribe tu dedicatoria',
        buttonLabel: 'Cargar foto/video',
        buttonLoadingLabel: 'Cargando',
        replaceContentLabel: 'Reemplazar este contenido',
        deleteContentLabel: 'Eliminar este contenido',
        cancelLabel: 'Cancelar',
        emptyState: {
            title: 'Aún no hay dedicatorias',
            subtitle: 'Comparte tus pensamientos y sentimientos. ¡Anímate a ser el primero en dejar una dedicatoria!'
        }
    },
    share: {
        shareThisMemorial: 'Compartir este memorial'
    }
};

export default memorials;
