const signUp = {
    title: 'Una vida para recordar',
    formTitle: 'Crear cuenta',
    form1: {
        title: 'Queremos conocerte mejor  ',
        subtitle: {
            1: 'Ingresa tus ',
            2: 'Nombres & Apellidos'
        },
        input1: {
            label: 'Nombres',
            placeholder: ''
        },
        input2: {
            label: 'Apellidos',
            placeholder: ''
        }
    },
    form2: {
        title: '¿Deseas compartir tu número telefónico?',
        subtitle: {
            1: 'Este paso es completamente ',
            2: 'opcional'
        },
        input1: {
            label: 'Número telefónico ',
            placeholder: ''
        },
        button: 'Continuar'
    },
    signUpWithGoogle: 'Regístrate con Google',
    signUpWithApple: 'Regístrate con Apple',
    userAlreadyRegister: {
        title: 'Ya existe una cuenta asociada al correo',
        subtitle: '¿Deseas iniciar sesión con este correo?',
        button: {
            1: 'Iniciar sesión',
            2: 'Probar con otro correo'
        }
    },
    haveAnAccount: {
        1: '¿Ya tienes una cuenta en Be soul?',
        2: 'Iniciar sesión'
    },
    seo: {
        title: 'Be Soul: Legacy planning app'
    }
};

export default signUp;
