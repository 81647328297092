const digitalLegacy = {
    seo: {
        title: 'Conservar recuerdos para generaciones | Be Soul Digital Legacy',
        description:
            'Descubra la función de legado digital de nuestra aplicación: Comparte de forma segura archivos, notas de voz, fotos y vídeos, garantizando un patrimonio digital duradero.'
    },
    banner: {
        title1: 'Legado',
        title2: 'Digital',
        subtitle1: 'Las experiencias de hoy, son regalos para el mañana',
        subtitle2: 'Comparte tus recuerdos con tus seres queridos'
    },
    carousel1Section: {
        carousel: {
            item1: {
                title: '1- Guarda tus recuerdos más preciados en legados',
                subtitle:
                    'Esta sección de la aplicación podrás guardar tus fotos/ videos, notas de voz y archivos importantes como tu testamento o documentos de gran importancia en un espacio seguro'
            },
            item2: {
                title: '2- Elige una forma de la liberación',
                subtitle:
                    'Comparte tus legados en una fecha especifica o después de tu muerte en caso de elegir la última opción el equipo de jurídico validará el informe de fallecimiento, asegurando una transición segura',
                subItem1: {
                    title: 'Cápsula del tiempo',
                    subtitle: 'Planifica una fecha de liberación para tu legado'
                },
                subItem2: {
                    title: 'Post-mortem',
                    subtitle: 'Libera tu legado después de tu muerte'
                }
            },
            item3: {
                title: '3- Compártelo con tus seres queridos',
                subtitle:
                    'La sección de familia ha sido diseñada para integrar a tus seres queridos en una agenda de contactos, a quienes les podrás compartir tu legado digital'
            }
        },
        button: 'Comenzar'
    },
    userStories: {
        title: 'Donde las historias de la vida perduran',
        subtitle: 'Conoce las historias de nuestros usuarios',
        stories: {
            '1': {
                name: 'Allie',
                text: 'El aplicativo no me parece complicado para personas que ya utilizan la tecnología o las redes, es fácil porque las simbologías son sencillas, mentalmente uno puede saber que quieres hacer'
            },
            '2': {
                name: 'Lizz',
                text: 'Está ordenada, limpia, y clara la Interfaz. Es muy intuitiva la navegación sobre lo que voy a ver o quién lo publicó y qué tipo de contenido será'
            },
            '3': {
                name: 'Anna',
                text: 'Es muy sencilla e intuitiva, y segura de usar. Puedes crear un legado sin mucho inconveniente'
            },
            '4': {
                name: 'Brenda',
                text: 'Estoy involucrada en la revisiones de legado y el cuidado de ancianos. Me encanta trabajar con ellos y ayudarlos a validar su vida. Encuentro la aplicación muy útil para cumplir esta función'
            }
        }
    },
    securityInfo: {
        title: 'Los tesoros de la vida, almacenados de forma segura',
        '1': {
            title: 'Defensa Inquebrantable',
            text: 'Incorporamos Google Cloud Armor, un escudo de protección líder en Google Cloud Platform (GCP). Filtra agresivamente el tráfico malicioso, asegurando la disponibilidad de tus recursos y aplicaciones.'
        },
        '2': {
            title: 'Acceso Autorizado',
            text: 'Nuestro sistema utiliza Firebase App Check y Tokens de Sesión para autenticar solicitudes de aplicaciones legítimas, garantizando un acceso seguro. Empleamos JSON Web Tokens (JWT) para autorización precisa y reglas especializadas en Firebase Storage para proteger los datos.'
        },
        '3': {
            title: 'Supervisión Incesante',
            text: 'Con un equipo de operaciones especializado, monitorizamos constantemente tus servicios y su seguridad. Logging, Cloud Armor y Security Command Center se unen para asegurar la detección y respuesta ante amenazas.'
        },
        '4': {
            title: 'Cautiva tus Datos',
            text: 'Mongo DB y soluciones propietarias se combinan para blindar tus datos con encriptación en todas las etapas. MongoDB Atlas garantiza el almacenamiento seguro con cifrado de datos en tránsito y en reposo.'
        },
        '5': {
            title: 'Transparencia Interna',
            text: 'Nuestros equipos internos siguen pautas estrictas de gestión de información. Los niveles de desarrollo aseguran entornos seguros desde lo local hasta la producción. Empleamos el servicio Secret Key del Security Command Center para gestionar credenciales y proteger datos sensibles.'
        },
        '6': {
            title: 'Potencia sin límites',
            text: 'Impulsamos nuestros productos con Cloud Run, un servicio serverless que asegura escalabilidad sin esfuerzo y seguridad óptima. La disponibilidad ilimitada de recursos garantiza una experiencia sin igual, manteniendo la calidad incluso durante picos de tráfico.'
        }
    },
    securityPlatforms: {
        title: 'Plataformas y seguridad en la nube',
        subtitle:
            'Nuestra promesa es clara, fusionamos tecnología líder con capas de seguridad infalibles para salvaguardar tus datos y mantener la confianza de nuestros usuarios en todo momento'
    }
};

export default digitalLegacy;
