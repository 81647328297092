import { Outlet } from 'react-router-dom';

// material-ui
import { Box, CssBaseline } from '@mui/material';

// project imports
import Header from './Header';
import { MemorialProvider } from 'contexts/MemorialContext';

// ==============================|| MAIN LAYOUT ||============================== //

const CreateElementLayout = () => {
    // const { isLoggedIn } = useAuth();

    return (
        <MemorialProvider>
            <Box sx={{ minHeight: '100vh' }}>
                <CssBaseline />

                {/* header */}
                <Header />
                <Outlet />
            </Box>
        </MemorialProvider>
    );
};

export default CreateElementLayout;
