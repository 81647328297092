const login = {
    title: 'A life to be remembered',
    formTitle: 'Log in',
    input1: {
        label: 'Email',
        placeholder: 'Enter your email address'
    },
    button: 'Continue with email',
    termsAndConditions: {
        1: 'By continuing, I certify that I have read and accept the ',
        2: 'Terms and conditions, ',
        3: 'Privacy Policies ',
        4: 'and I confirm that I am ',
        5: 'Over 18 years of age. '
    },
    signInWithGoogle: 'Access with Google',
    signInWithApple: 'Access with Apple',
    haveAnAccount: {
        1: "Don't have a Be soul account?",
        2: 'Get started'
    },
    otp: {
        title: 'Enter the code you received',
        subtitle: 'We sent the confirmation code to: ',
        input: {
            label: 'Confirmation code'
        },
        button: 'Continue',
        resend: 'Resend code'
    },
    userNotFound: {
        title: 'There is no account associated with the email',
        subtitle: 'Do you want to create an account with this email?',
        button: {
            1: 'Create Account',
            2: 'Try another email'
        }
    },
    seo: {
        title: 'Be Soul: Log In'
    }
};

export default login;
