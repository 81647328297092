const comingSoon = {
    seo: {
        title: 'Muy pronto',
        description: 'Dummy description for coming soon section'
    },
    title: '¡Pronto podrás acceder al foro!',
    paragraph: `Estamos trabajando en esta sección de la web: un espacio dedicado al bienestar emocional, donde encontrarás noticias y recursos que te inspirarán para cultivar una vida plena.
    Permanece atento para descubrir todo lo que estamos preparando para ti`
};

export default comingSoon;
