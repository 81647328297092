import axios, { AxiosRequestConfig, CreateAxiosDefaults, InternalAxiosRequestConfig } from 'axios';
import { BESOUL_API_KEY } from 'environment';
import { getToken } from 'firebase/app-check';
import { generatePayloadSignature } from 'utils/generatePayloadSignatureBase';
import { appCheck } from '../../firebase/config';

interface ICreateAxiosOption {
    options: CreateAxiosDefaults;
    generateSignature: (config: IAppRequestConfig, xTimestamp: string) => string;
}

export interface IAppRequestConfig extends InternalAxiosRequestConfig {
    authorization?: boolean;
    otp?: boolean;
}

export interface IAppConfig extends AxiosRequestConfig {
    authorization?: boolean;
    otp?: boolean;
}

export function createAxiosClient(props: ICreateAxiosOption) {
    const client = axios.create(props.options);

    client.interceptors.request.use(
        async (config: IAppRequestConfig) => {
            try {
                const xTimestamp = Date.now().toString();
                const params = new URLSearchParams(new URL(`${config.baseURL}${config.url}` ?? '').search);
                const body = config.data ?? {};
                const url = config.url?.replace(/.*\/\//, '');

                let queryParams: Record<string, string> = {};
                params.forEach((value, key) => {
                    queryParams[key] = value;
                });

                config.headers['BESOUL_API_KEY'] = BESOUL_API_KEY;
                config.headers['authorization'] = localStorage.getItem('jwt') || '';
                config.headers['x-firebase-appcheck'] = (await getToken(appCheck)).token;
                config.headers['app-version'] = 'Web';
                config.headers['x-timestamp'] = xTimestamp;
                config.headers['x-crypt'] = generatePayloadSignature({
                    xTimestamp,
                    url,
                    method: config.method?.toUpperCase(),
                    body,
                    searchParams: queryParams
                });
                config.headers['X-Frame-Options'] = 'SAMEORIGIN';
                config.headers['X-Content-Type-Options'] = 'nosniff';
                config.headers['Strict-Transport-Security'] = 'max-age=31536000; includeSubDomains; preload';
                return config;
            } catch (error: any) {
                if (error.response.data.statusCode === '401') {
                    console.error('FORCE LOG OUT');
                }
                console.error(error.response);
                return Promise.reject(error);
            }
        },
        (error) => Promise.reject(error)
    );

    return client;
}
