import { createApi } from '@reduxjs/toolkit/query/react';
import { httBaseQueryWithRetry } from 'services/rtk/baseQuery';

export const emailApi = createApi({
    reducerPath: 'emailApi',
    baseQuery: httBaseQueryWithRetry,
    endpoints: (builder) => ({
        LoginPasswordLess: builder.mutation<any, { to: string; language: 'en' | 'es' }>({
            query: (data) => {
                return {
                    url: '/core/email/send/passwordless-login',
                    method: 'post',
                    data
                };
            }
        })
    })
});

export const { useLoginPasswordLessMutation } = emailApi;
