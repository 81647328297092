const support = {
    seo: {
        title: 'Soporte | Be Soul',
        description:
            'Encuentra respuestas a preguntas comunes, guías de solución de problemas y conecta con nuestro dedicado equipo de soporte.'
    },
    title: 'Soporte',
    subtitle:
        'En Be Soul, nos esforzamos por brindar la mejor atención. Agradeceríamos que compartas tus inquietudes con nosotros para que podamos seguir mejorando',
    form: {
        input1: {
            name: 'Tu nombre',
            placeholder: 'Nombre'
        },
        radioButton: {
            1: 'Vía correo',
            2: 'Vía móvil'
        },
        input2: {
            name: 'Tu correo',
            placeholder: 'Correo'
        },
        input3: {
            name: 'Tu teléfono',
            placeholder: 'Teléfono'
        },
        textArea: {
            name: 'Déjanos un mensaje',
            placeholder: 'Escribe tus inquietudes aquí'
        },
        submitButton: 'Enviar'
    },
    success: {
        1: 'Hemos recibido tu inquietud y estamos trabajando para abordar cualquier preocupación que puedas tener',
        2: '¡Gracias por confiar en nosotros!',
        button: 'Volver al inicio'
    }
};

export default support;
