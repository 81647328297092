// material-ui
import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

// assets
import defaultColor from 'assets/scss/_themes-vars.module.scss';
import theme1 from 'assets/scss/_theme1.module.scss';
import theme2 from 'assets/scss/_theme2.module.scss';
import theme3 from 'assets/scss/_theme3.module.scss';
import theme4 from 'assets/scss/_theme4.module.scss';
import theme5 from 'assets/scss/_theme5.module.scss';
import theme6 from 'assets/scss/_theme6.module.scss';
import besoulPrincipalTheme from 'assets/scss/_besoul_principal_theme.module.scss';

// types
import { ColorProps } from 'types';

// ==============================|| DEFAULT THEME - PALETTE ||============================== //

const Palette = (navType: PaletteMode, presetColor: string) => {
    let colors: ColorProps;
    switch (presetColor) {
        case 'theme1':
            colors = theme1;
            break;
        case 'theme2':
            colors = theme2;
            break;
        case 'theme3':
            colors = theme3;
            break;
        case 'theme4':
            colors = theme4;
            break;
        case 'theme5':
            colors = theme5;
            break;
        case 'theme6':
            colors = theme6;
            break;
        case 'besoulTheme':
            colors = besoulPrincipalTheme;
            break;
        case 'default':
        default:
            colors = defaultColor;
    }

    return createTheme({
        palette: {
            mode: navType,
            common: {
                black: colors.black,
                white: colors.white
            },
            primary: {
                light: navType === 'dark' ? colors.darkPrimaryLight : colors.primaryLight,
                main: navType === 'dark' ? colors.darkPrimaryMain : colors.primaryMain,
                dark: navType === 'dark' ? colors.darkPrimaryDark : colors.primaryDark,
                50: navType === 'dark' ? colors.darkPrimary50 : colors.primary50,
                100: navType === 'dark' ? colors.darkPrimary100 : colors.primary100,
                200: navType === 'dark' ? colors.darkPrimary200 : colors.primary200,
                300: navType === 'dark' ? colors.darkPrimary300 : colors.primary300,
                400: navType === 'dark' ? colors.darkPrimary400 : colors.primary400,
                500: navType === 'dark' ? colors.darkPrimary500 : colors.primary500,
                600: navType === 'dark' ? colors.darkPrimary600 : colors.primary600,
                700: navType === 'dark' ? colors.darkPrimary700 : colors.primary700,
                800: navType === 'dark' ? colors.darkPrimary800 : colors.primary800,
                900: navType === 'dark' ? colors.darkPrimary900 : colors.primary900
            },
            secondary: {
                light: navType === 'dark' ? colors.darkSecondaryLight : colors.secondaryLight,
                main: navType === 'dark' ? colors.darkSecondaryMain : colors.secondaryMain,
                dark: navType === 'dark' ? colors.darkSecondaryDark : colors.secondaryDark,
                50: navType === 'dark' ? colors.darkSecondary50 : colors.secondary50,
                100: navType === 'dark' ? colors.darkSecondary100 : colors.secondary100,
                200: navType === 'dark' ? colors.darkSecondary200 : colors.secondary200,
                300: navType === 'dark' ? colors.darkSecondary300 : colors.secondary300,
                400: navType === 'dark' ? colors.darkSecondary400 : colors.secondary400,
                500: navType === 'dark' ? colors.darkSecondary500 : colors.secondary500,
                600: navType === 'dark' ? colors.darkSecondary600 : colors.secondary600,
                700: navType === 'dark' ? colors.darkSecondary700 : colors.secondary700,
                800: navType === 'dark' ? colors.darkSecondary800 : colors.secondary800,
                900: navType === 'dark' ? colors.darkSecondary900 : colors.secondary900
            },
            error: {
                light: colors.errorLight,
                main: colors.errorMain,
                dark: colors.errorDark
            },
            orange: {
                light: colors.orangeLight,
                main: colors.orangeMain,
                dark: colors.orangeDark
            },
            warning: {
                light: colors.warningLight,
                main: colors.warningMain,
                dark: colors.warningDark
            },
            success: {
                light: colors.successLight,
                200: colors.success200,
                main: colors.successMain,
                dark: colors.successDark
            },
            grey: {
                50: navType === 'dark' ? colors.darkNeutral50 : colors.neutral50,
                100: navType === 'dark' ? colors.darkNeutral100 : colors.neutral100,
                200: navType === 'dark' ? colors.darkNeutral200 : colors.neutral200,
                300: navType === 'dark' ? colors.darkNeutral300 : colors.neutral300,
                400: navType === 'dark' ? colors.darkNeutral400 : colors.neutral400,
                500: navType === 'dark' ? colors.darkNeutral500 : colors.neutral500,
                600: navType === 'dark' ? colors.darkNeutral600 : colors.neutral600,
                700: navType === 'dark' ? colors.darkNeutral700 : colors.neutral700,
                800: navType === 'dark' ? colors.darkNeutral800 : colors.neutral800,
                900: navType === 'dark' ? colors.darkNeutral900 : colors.neutral900
            },
            dark: {
                light: colors.darkTextPrimary,
                main: colors.darkLevel1,
                dark: colors.darkLevel2,
                800: colors.darkBackground,
                900: colors.darkPaper
            },
            text: {
                primary: navType === 'dark' ? colors.darkTextPrimary : colors.grey700,
                secondary: navType === 'dark' ? colors.darkTextSecondary : colors.grey500,
                dark: navType === 'dark' ? colors.darkTextPrimary : colors.grey900,
                hint: colors.grey100
            },
            divider: navType === 'dark' ? colors.darkTextPrimary : colors.grey200,
            background: {
                paper: navType === 'dark' ? colors.darkLevel2 : colors.paper,
                default: navType === 'dark' ? colors.darkPaper : colors.paper
            }
        }
    });
};

export default Palette;
