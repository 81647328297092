import { Button } from '@mui/material';
import React from 'react';
interface PropertyBreakPoints {
    xs?: string | null;
    sm?: string | null;
    md?: string | null;
    lg?: string | null;
    xl?: string | null;
    xxl?: string | null;
}

export interface SxBreakpoints {
    xs?: object | null;
    sm?: object | null;
    md?: object | null;
    lg?: object | null;
    xl?: object | null;
    xxl?: object | null;
}

export interface ButtonProps {
    children: React.ReactNode;
    onClick?: any;
    // onClick?: MouseEventHandler<HTMLButtonElement> | FormEvent<HTMLButtonElement> | undefined;
    textColor?: string;
    variant?: 'outlined' | 'contained' | 'text';
    buttonColor?: 'primary' | 'secondary' | 'success' | 'error' | 'inherit' | 'info' | 'warning';
    sx?: SxBreakpoints;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    width?: string | PropertyBreakPoints;
    padding?: string | PropertyBreakPoints;
    height?: string | PropertyBreakPoints;
    margin?: string | PropertyBreakPoints;
    fullWidth?: boolean;
    startIcon?: any;
    endIcon?: any;
}

const CustomButton = ({
    children,
    onClick,
    textColor,
    variant,
    buttonColor,
    sx = { xs: {}, sm: {}, md: {}, lg: {}, xl: {}, xxl: {} },
    type,
    disabled = false,
    width,
    padding,
    height,
    margin,
    fullWidth,
    startIcon,
    endIcon
}: ButtonProps) => {
    const { xs: extraSmallSx, sm: smallSx, md: mediumSx, lg: largeSx, xl: extraLargeSx, xxl: extraExtraLargeSx } = sx;
    return (
        <Button
            fullWidth={fullWidth}
            startIcon={startIcon}
            endIcon={endIcon}
            disabled={disabled}
            color={buttonColor}
            type={type}
            variant={variant}
            onClick={onClick}
            sx={(theme) => ({
                boxShadow: 'none',
                textTransform: 'none',
                color: textColor,
                width: typeof width === 'string' || typeof width === 'number' ? width : undefined,
                height: typeof height === 'string' || typeof height === 'number' ? height : undefined,
                padding: typeof padding === 'string' || typeof padding === 'number' ? padding : undefined,
                margin: typeof margin === 'string' || typeof margin === 'number' ? margin : undefined,
                '&.Mui-disabled': {
                    backgroundColor: variant === 'contained' && disabled ? '#E5F1F5' : 'transparent'
                },
                [theme.breakpoints.up('xs')]: {
                    width: typeof width === 'object' ? width['xs'] : undefined,
                    height: typeof height === 'object' ? height['xs'] : undefined,
                    margin: typeof margin === 'object' ? margin['xs'] : undefined,
                    padding: typeof padding === 'object' ? padding['xs'] : undefined,
                    ...extraSmallSx
                },
                [theme.breakpoints.up('sm')]: {
                    width: typeof width === 'object' ? width['sm'] : undefined,
                    height: typeof height === 'object' ? height['sm'] : undefined,
                    margin: typeof margin === 'object' ? margin['sm'] : undefined,
                    padding: typeof padding === 'object' ? padding['sm'] : undefined,
                    ...smallSx
                },
                [theme.breakpoints.up('md')]: {
                    width: typeof width === 'object' ? width['md'] : undefined,
                    height: typeof height === 'object' ? height['md'] : undefined,
                    margin: typeof margin === 'object' ? margin['md'] : undefined,
                    padding: typeof padding === 'object' ? padding['md'] : undefined,
                    ...mediumSx
                },
                [theme.breakpoints.up('lg')]: {
                    width: typeof width === 'object' ? width['lg'] : undefined,
                    height: typeof height === 'object' ? height['lg'] : undefined,
                    margin: typeof margin === 'object' ? margin['lg'] : undefined,
                    padding: typeof padding === 'object' ? padding['lg'] : undefined,
                    ...largeSx
                },
                [theme.breakpoints.up('xl')]: {
                    width: typeof width === 'object' ? width['xl'] : undefined,
                    height: typeof height === 'object' ? height['xl'] : undefined,
                    margin: typeof margin === 'object' ? margin['xl'] : undefined,
                    padding: typeof padding === 'object' ? padding['xl'] : undefined,
                    ...extraLargeSx
                },
                [`@media (width >= 1960px)`]: {
                    width: typeof width === 'object' ? width['xxl'] : undefined,
                    height: typeof height === 'object' ? height['xxl'] : undefined,
                    margin: typeof margin === 'object' ? margin['xxl'] : undefined,
                    padding: typeof padding === 'object' ? padding['xxl'] : undefined,
                    ...extraExtraLargeSx
                }
            })}
        >
            {children}
        </Button>
    );
};

export default CustomButton;
