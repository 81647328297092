const comingSoon = {
    seo: {
        title: 'Coming soon',
        description: 'Dummy description for coming soon section'
    },
    title: 'Soon you will be able to access the forum!',
    paragraph: `We are working on this section of the website: a space dedicated to emotional well-being, where you will find news and resources that will inspire you to cultivate a fulfilling life.
    Stay tuned to discover everything we are preparing for you`
};

export default comingSoon;
