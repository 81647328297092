import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_BASE_URL } from 'environment';
import { IAppConfig, IAppRequestConfig, createAxiosClient } from './createAxiosClient';
import { ApiConfig, ApiGenericResponse } from './http.types';
import { getGeneralApiProblem } from './httpProblem';
import { generatePayloadSignature } from 'utils/generatePayloadSignatureBase';

/**
 * Configuring the axios instance.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
    url: API_BASE_URL || '',
    timeout: 10000,
    responseType: 'json'
    // withCredentials: true
};

function parseQueryParams(url: string) {
    const queryParams: Record<string, string> = {};

    const queryString = url.split('?')[1];
    if (queryString) {
        const paramPairs = queryString.split('&');

        paramPairs.forEach((paramPair) => {
            const [key, value] = paramPair.split('=');
            if (key && value) {
                queryParams[key] = value;
            }
        });
    }

    return queryParams;
}

export function generateSignature(config: IAppRequestConfig, xTimestamp: string) {
    const url = config.baseURL ? config.baseURL.replace(/.*\/\//, '') + config.url : '';
    const body = config.data ?? {};

    return generatePayloadSignature({
        xTimestamp,
        url,
        method: config.method ? config.method.toUpperCase() : 'get',
        body,
        searchParams: parseQueryParams(url)
    });
}

class Http {
    instance: AxiosInstance;
    config: ApiConfig;

    constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
        this.config = config;
        this.instance = createAxiosClient({
            options: {
                baseURL: this.config.url,
                timeout: this.config.timeout,
                responseType: this.config.responseType,
                // withCredentials: this.config.withCredentials,
                headers: {
                    'Content-Type': 'application/json'
                }
            },
            generateSignature
        });
    }

    generic = async (config: IAppConfig): Promise<ApiGenericResponse> => {
        try {
            const resp: AxiosResponse<ApiGenericResponse> = await this.instance(config);

            return resp.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw getGeneralApiProblem(error);
            }
            throw error;
        }
    };

    get = async (url: string, config?: AxiosRequestConfig): Promise<ApiGenericResponse> => {
        try {
            const resp: AxiosResponse<ApiGenericResponse> = await this.instance.get(url, config);

            return resp.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw getGeneralApiProblem(error);
            }
            throw error;
        }
    };

    post = async (url: string, body: any, config?: AxiosRequestConfig): Promise<ApiGenericResponse> => {
        try {
            const resp: AxiosResponse<ApiGenericResponse> = await this.instance.post(url, body, config);

            return resp.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw getGeneralApiProblem(error);
            }
            throw error;
        }
    };

    patch = async (url: string, body: any, config?: AxiosRequestConfig): Promise<ApiGenericResponse> => {
        try {
            const resp: AxiosResponse<ApiGenericResponse> = await this.instance.patch(url, body, config);

            return resp.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw getGeneralApiProblem(error);
            }
            throw error;
        }
    };

    put = async (url: string, body: any, config?: AxiosRequestConfig): Promise<ApiGenericResponse> => {
        try {
            const resp: AxiosResponse<ApiGenericResponse> = await this.instance.put(url, body, config);

            return resp.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw getGeneralApiProblem(error);
            }
            throw error;
        }
    };

    delete = async (url: string, config?: AxiosRequestConfig): Promise<ApiGenericResponse> => {
        try {
            const resp: AxiosResponse<ApiGenericResponse> = await this.instance.delete(url, config);

            return resp.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw getGeneralApiProblem(error);
            }
            throw error;
        }
    };
}

export const http = new Http();
