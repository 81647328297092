const memorial = {
    feed: {
        container: {
            header: {
                headerMyMemorial: 'Mis memoriales',
                headerSigned: 'Firmados',
                headerShare: 'Compartidos conmigo',
                headerExplore: 'Explorar',
                rightTextBtn: 'Ver todos'
            },
            item: {
                createdBy: 'Creado por:',
                signed: {
                    1: 'Firmado {{times}} veces',
                    2: 'Firmado {{times}} vez'
                },
                edit: 'Editar'
            },
            emptyState: {
                myMemorials: {
                    title: 'Mis memorials',
                    text1: 'Aún no creas un memorial',
                    text2: 'Crea un memorial para honrar a tus seres queridos.'
                },
                signed: {
                    title: 'Firmados',
                    text1: 'Sin memorials firmados',
                    text2: 'Aún no has firmado ningún memorial'
                },
                shared: {
                    title: 'Compartidos conmigo',
                    text1: 'Memoriales compartidos',
                    text2: 'Aun nadie a compartido un memorial'
                }
            }
        },
        sections: {
            page: {
                myMemorials: {
                    title: 'Mis memoriales'
                },
                signed: {
                    title: 'Firmados'
                },
                shared: {
                    title: 'Compartidos conmigo'
                },
                explore: {
                    title: 'Explorar'
                }
            },
            filter: {
                placeholder: 'Buscar',
                placeholder2: 'Buscar un memorial',
                all: 'Todos',
                people: 'Personas',
                pets: 'Mascotas',
                drafts: 'Borradores'
            }
        }
    },
    view: {
        tabMemorial: 'Memorial',
        tabGallery: 'Recuerdos',
        inMemoryOf: 'A la memoria de',
        share: {
            download: 'Descargar',
            shareWith: 'Compartir memorial',
            copy: 'Copiar link',
            text: 'Visita este memorial en Be soul'
        }
    }
};

export default memorial;
