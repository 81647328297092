import { Box, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Text from 'ui-component/Text/Text';
import BeSoulLogo from '../../../assets/icons/svg/besoul_logo.svg';
import Clouds1 from '../../../assets/images/svg/Clouds1.svg';
import Clouds2 from '../../../assets/images/svg/Clouds2.svg';
import GooglePlay from 'ui-component/Buttons/GooglePlay';
import AppStore from 'ui-component/Buttons/AppStore';
import { Helmet } from 'react-helmet';

const DownloadApp = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            width="100vw"
            height="100vh"
            sx={{ backgroundColor: theme.palette.primary[300], position: 'relative', paddingTop: '100px' }}
        >
            <Helmet>
                {process.env.REACT_APP_IS_PROD_ENV === 'false' && <meta name="robots" content="noindex, nofollow" />}
                <link rel="canonical" href="https://besoul.app/download-app" />
                <meta name="title" content={t('common.downloadApp.seo.title')} />
                <meta name="description" content={t('common.downloadApp.seo.description')} />
                <title>{t('common.downloadApp.seo.title')}</title>
            </Helmet>
            <Box
                component="img"
                src={Clouds1}
                alt="clouds"
                width={{ xs: '200px', md: '250px', lg: '300px' }}
                sx={{ position: 'absolute', left: 0, top: '50px' }}
            />
            <Box
                component="img"
                src={Clouds2}
                alt="clouds"
                width={{ xs: '60px', sm: '80px', md: '100px', lg: '150px' }}
                sx={{ position: 'absolute', right: 0, top: '25%' }}
            />
            <Stack direction="column" alignItems="center">
                <Box component="img" src={BeSoulLogo} alt="BeSoul Logo" width={{ xs: '300px', sm: '450px', md: '600px', lg: '900px' }} />
                <Text
                    preset={{ xs: 'titleLarge', md: 'displaySmall' }}
                    textColor={theme.palette.common.white}
                    component="h1"
                    sx={{
                        xs: {
                            marginTop: '10px',
                            marginBottom: '25px'
                        }
                    }}
                >
                    {t('common.downloadApp.title')}
                </Text>
                <Text
                    preset={{ xs: 'titleLarge', md: 'displaySmall' }}
                    textColor={theme.palette.common.white}
                    component="h1"
                    fontWeight={600}
                    sx={{
                        xs: {
                            marginTop: '0',
                            marginBottom: '30px'
                        },
                        md: {
                            marginTop: '-15px'
                        }
                    }}
                >
                    {t('common.downloadApp.subtitle')}
                </Text>
                <Stack direction="row" justifyContent="space-evenly" alignItems="center" width={{ md: '400px' }}>
                    <AppStore />
                    <GooglePlay />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default DownloadApp;
