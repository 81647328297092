import { useEffect, useState } from 'react';

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
};

const getDevice = (width: number) => {
    let isPhone = false;
    let isTablet = false;
    let isDesktop = false;

    if (width <= 425) {
        isPhone = true;
    } else if (width <= 768) {
        isTablet = true;
    } else {
        isDesktop = true;
    }

    return { isPhone, isTablet, isDesktop };
};

export default function useScreenSize() {
    const initialState = getDevice(getWindowDimensions().width);
    const [screenSize, setScreenSize] = useState(initialState);

    useEffect(() => {
        function handleResize() {
            setScreenSize(getDevice(getWindowDimensions().width));
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return screenSize;
}
