import { retry } from '@reduxjs/toolkit/dist/query/react';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosRequestConfig } from 'axios';
import { http } from '../http';
import { ApiGenericResponse } from '../http/http.types';

export const httpBaseQuery =
    (): BaseQueryFn<
        {
            url: string;
            method: AxiosRequestConfig['method'];
            data?: AxiosRequestConfig['data'];
            authorization?: boolean;
            otp?: boolean;
        },
        ApiGenericResponse,
        unknown
    > =>
    async ({ url, method, data, authorization, otp }) => {
        try {
            const response = await http.generic({
                url,
                method,
                data,
                authorization,
                otp
            });

            if (response.result) {
                return { data: response.result };
            } else {
                return { data: response };
            }
        } catch (httpError) {
            return { error: httpError };
        }
    };

export const httBaseQueryWithRetry = retry(httpBaseQuery(), {
    // @ts-ignore
    maxRetries: 5,
    // @ts-ignore
    retryCondition: (error: any) => {
        return error.code >= 500;
    }
});
