import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import CustomButton from 'ui-component/CustomButtom';
import Text from 'ui-component/Text/Text';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

interface ModalProps {
    title?: string;
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
    buttonRender?: React.ReactNode;
    headerRender?: React.ReactNode;
    footerRender?: React.ReactNode;
    closePosition?: 'left' | 'right';
    sxContent?: any;
    handleCloseAfterClose?: () => void;
    titleStyle?: any;
}

const Modal = ({
    title,
    titleStyle,
    openModal,
    setOpenModal,
    children,
    buttonRender,
    closePosition = 'right',
    headerRender,
    footerRender,
    sxContent,
    handleCloseAfterClose
}: ModalProps) => {
    const handleClose = () => {
        setOpenModal(false);
        if (handleCloseAfterClose) {
            handleCloseAfterClose();
        }
    };

    return (
        <React.Fragment>
            <BootstrapDialog fullWidth onClose={handleClose} aria-labelledby="customized-dialog-title" open={openModal}>
                {title && (
                    <DialogTitle sx={{ mt: 1, p: 2 }} id="customized-dialog-title">
                        <Text preset={'headlineSmall'} fontWeight={700} sx={{ xs: { textAlign: 'center', ...titleStyle } }}>
                            {title}
                        </Text>
                    </DialogTitle>
                )}

                <Box display={'flex'} width={'100%'}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: closePosition === 'right' ? 8 : 'auto',
                            left: closePosition === 'left' ? 8 : 'auto',
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {headerRender}
                </Box>

                <DialogContent sx={sxContent}>{children}</DialogContent>
                {buttonRender && (
                    <DialogActions>
                        <CustomButton onClick={handleClose}>{'Save changes'}</CustomButton>
                    </DialogActions>
                )}
                {footerRender && (
                    <Box display={'flex'} width={'100%'}>
                        {footerRender}
                    </Box>
                )}
            </BootstrapDialog>
        </React.Fragment>
    );
};

export default Modal;
