const digitalLegacy = {
    seo: {
        title: 'Preserving Memories for Generations | Be Soul Digital Legacy',
        description: `Discover our app's Digital Legacy feature: Safely share archives, voice notes, photos, and videos, ensuring enduring digital heritage`
    },
    banner: {
        title1: 'Digital',
        title2: 'Legacy',
        subtitle1: "Today's experiences are gifts for tomorrow",
        subtitle2: 'Share your memories with your loved ones'
    },
    carousel1Section: {
        carousel: {
            item1: {
                title: '1- Save your most precious memories in legacies',
                subtitle:
                    'In this section of the application you can save your photos/videos, voice notes and important files such as your will or documents of great importance in a safe space'
            },
            item2: {
                title: '2- Choose a release form',
                subtitle:
                    'Share your legacies on a specific date or after your death. If you choose the latter option, the legal team will validate the death report, ensuring a safe transition',
                subItem1: {
                    title: 'Time capsule',
                    subtitle: 'Plan a release date for your legacy'
                },
                subItem2: {
                    title: 'Post-mortem',
                    subtitle: 'Release your legacy after your death'
                }
            },
            item3: {
                title: '3- Share it with your loved ones',
                subtitle:
                    'The family section has been designed to integrate your loved ones into a contact agenda, with whom you can share your digital legacy'
            }
        },
        button: 'Start'
    },
    userStories: {
        title: "Where life's stories endure",
        subtitle: "Learn about our users' stories",
        stories: {
            '1': {
                name: 'Allie',
                text: 'The application does not seem complicated to me for people who already use technology or networks, it is easy because the symbols are simple, mentally you can know what you want to do'
            },
            '2': {
                name: 'Lizz',
                text: 'The Interface is orderly, clean, and clear. It is very intuitive to navigate what I am going to see or who posted it and what type of content it will be'
            },
            '3': {
                name: 'Anna',
                text: 'It is very simple and intuitive, and safe to use. You can create a legacy without much hassle'
            },
            '4': {
                name: 'Brenda',
                text: 'I am involved in legacy reviews and elder care. I love working with them and helping them validate their lives. I find the application very useful in fulfilling this function'
            }
        }
    },
    securityInfo: {
        title: "Life's Treasures, Safely Stored",
        '1': {
            title: 'Unbreakable Defense',
            text: 'We incorporate Google Cloud Armor, a leading protection shield on Google Cloud Platform (GCP). Aggressively filters malicious traffic, ensuring the availability of your resources and applications.'
        },
        '2': {
            title: 'Authorized access',
            text: 'Our system uses Firebase App Check and Session Tokens to authenticate legitimate application requests, ensuring secure access. We employ JSON Web Tokens (JWT) for precise authorization and specialized rules in Firebase Storage to protect data.'
        },
        '3': {
            title: 'Incessant supervision',
            text: 'With a specialized operations team, we constantly monitor your services and their security. Logging, Cloud Armor and Security Command Center come together to ensure detection and response to threats.'
        },
        '4': {
            title: 'Captivate your data',
            text: 'Mongo DB and proprietary solutions combine to shield your data with encryption at every stage. MongoDB Atlas ensures secure, encrypted storage of data in transit and at rest.'
        },
        '5': {
            title: 'Internal transparency',
            text: "Our internal teams follow strict information management guidelines. Development levels ensure safe environments from local to production. We use the Security Command Center's Secret Key service to manage credentials and protect sensitive data."
        },
        '6': {
            title: 'Unlimited power',
            text: 'We power our products with Cloud Run, a serverless service that ensures effortless scalability and optimal security. The unlimited availability of resources guarantees an unparalleled experience, maintaining quality even during traffic peaks.'
        }
    },
    securityPlatforms: {
        title: 'Cloud platforms and security',
        subtitle:
            'Our promise is clear, we merge leading technology with infallible security layers to safeguard your data and maintain the trust of our users at all times'
    }
};

export default digitalLegacy;
