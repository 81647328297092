const login = {
    title: 'Una vida para recordar',
    formTitle: 'Iniciar sesión',
    input1: {
        label: 'Correo electrónico',
        placeholder: 'Ingresa tu correo electrónico'
    },
    button: 'Continuar con correo',
    termsAndConditions: {
        1: 'Al continuar, certifico que he leído y acepto los ',
        2: 'Términos y condiciones, ',
        3: 'Políticas de privacidad ',
        4: 'y confirmo que soy ',
        5: 'Mayor de 18 años.'
    },
    signInWithGoogle: 'Accede con Google',
    signInWithApple: 'Accede con Apple',
    haveAnAccount: {
        1: '¿No tienes una cuenta Be soul?',
        2: 'Crear una cuenta'
    },
    otp: {
        title: 'Ingresa el código que recibiste ',
        subtitle: 'Enviamos el código de confirmación a: ',
        input: {
            label: 'Código de confirmación'
        },
        button: 'Continuar',
        resend: 'Reenviar código'
    },
    userNotFound: {
        title: 'No existe una cuenta asociada al correo',
        subtitle: '¿Deseas crear una cuenta con este correo?',
        button: {
            1: 'Crear cuenta',
            2: 'Probar con otro correo'
        }
    },
    seo: {
        title: 'Be Soul: Inicia sesión'
    }
};

export default login;
