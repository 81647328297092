import { useState } from 'react';
import { Box, Collapse, Divider, Drawer, List, ListItem, ListItemIcon, Menu, MenuItem, Stack, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { ReactComponent as BeSoulLogo } from '../../../assets/icons/svg/besoul_logo.svg';
import { ReactComponent as LanguagesIcon } from '../../../assets/icons/svg/languages_icon.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/svg/white_arrow_down.svg';
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/svg/white_arrow_up.svg';
import { ReactComponent as MobileMenuIcon } from '../../../assets/icons/svg/white_lucide_menu.svg';
import PersonIcon from '@mui/icons-material/Person';
import Text from 'ui-component/Text/Text';
import { useTranslation } from 'react-i18next';
import CustomLink from 'ui-component/CustomLink';
import CustomButton from 'ui-component/CustomButtom';
import { useSelector } from 'react-redux';
import useAuth from 'hooks/useAuth';

const LanguageMenu = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const changeLanguage = (locale: string) => {
        i18n.changeLanguage(locale);
        handleClose();
    };

    return (
        <>
            <CustomButton onClick={handleClick}>
                <LanguagesIcon /> {'&'} {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </CustomButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ sx: { backgroundColor: theme.palette.primary.main } }}
            >
                <MenuItem onClick={() => changeLanguage('es-419')}>
                    <Text textColor={theme.palette.common.white} preset="titleLarge" fontWeight={700}>
                        {t('common.header.languages.spanish')}
                    </Text>
                </MenuItem>
                <MenuItem onClick={() => changeLanguage('en-US')}>
                    <Text textColor={theme.palette.common.white} preset="titleLarge" fontWeight={700}>
                        {t('common.header.languages.english')}
                    </Text>
                </MenuItem>
            </Menu>
        </>
    );
};

const MobileMenuList = ({ handleClose }: { handleClose: () => void }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { isLoggedIn } = useAuth();
    const [openNested, setOpenNested] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const useInfo = useSelector((state: any) => state.user.userInfo);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenNested = () => {
        setOpenNested(!openNested);
    };

    const changeLanguage = (locale: string) => {
        i18n.changeLanguage(locale);
        handleClose();
    };

    return (
        <Box sx={{ width: '300px' }}>
            <List>
                <ListItem sx={{ marginTop: 1 }}>
                    <CustomLink onClick={() => handleClose()} to={'/digital-legacy'}>
                        <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleSmall">
                            {t('common.header.legacy')}
                        </Text>
                    </CustomLink>
                </ListItem>
                <ListItem sx={{ marginTop: 1 }}>
                    <CustomLink onClick={() => handleClose()} to={'/memorials'}>
                        <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleSmall">
                            {t('common.header.memorials')}
                        </Text>
                    </CustomLink>
                </ListItem>
                <ListItem sx={{ marginTop: 1 }}>
                    <CustomLink onClick={() => handleClose()} to={'/calm'}>
                        <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleSmall">
                            {t('common.header.calm')}
                        </Text>
                    </CustomLink>
                </ListItem>
                <ListItem sx={{ marginTop: 1 }}>
                    <CustomLink onClick={() => handleClose()} to={'/pricing'}>
                        <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleSmall">
                            {t('common.header.plans')}
                        </Text>
                    </CustomLink>
                </ListItem>
                {/* <ListItem sx={{ marginTop: 1 }}>
                    <CustomLink onClick={() => handleClose()} to={'#'}>
                        <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleSmall">
                            {t('common.header.about')}
                        </Text>
                    </CustomLink>
                </ListItem> */}
                <ListItem sx={{ marginTop: 1 }}>
                    <CustomLink to={'https://blog.besoul.app/'} target="_blank" onClick={() => handleClose()}>
                        <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleSmall">
                            {t('common.header.blog')}
                        </Text>
                    </CustomLink>
                </ListItem>
                {isLoggedIn && useInfo ? (
                    <Box position="fixed" bottom={0} right={0}>
                        <ListItem>
                            <CustomButton onClick={handleOpenMenu}>
                                {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                {useInfo.profilePhoto ? (
                                    <Box>
                                        <CustomButton>
                                            <Box
                                                component="img"
                                                src={useInfo.profilePhoto}
                                                sx={{ borderRadius: '50px', width: '50px', height: '50px', cursor: 'pointer' }}
                                            />
                                        </CustomButton>
                                    </Box>
                                ) : (
                                    <PersonIcon sx={{ width: '100%', height: 'auto', color: '#fff' }} />
                                )}
                            </CustomButton>
                        </ListItem>
                        <Menu
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleCloseMenu}
                            MenuListProps={{ sx: { backgroundColor: theme.palette.primary.main } }}
                        >
                            <MenuItem>
                                <CustomLink to={'/login'}>
                                    <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleSmall">
                                        {t('common.header.memorials')}
                                    </Text>
                                </CustomLink>
                            </MenuItem>
                        </Menu>
                    </Box>
                ) : (
                    <>
                        <ListItem sx={{ marginTop: 1 }}>
                            <CustomLink onClick={() => handleClose()} to={'/login'}>
                                <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleSmall">
                                    {t('common.header.login')}
                                </Text>
                            </CustomLink>
                        </ListItem>
                        <ListItem sx={{ marginTop: 1 }}>
                            <CustomLink onClick={() => handleClose()} to={'/sign-up'}>
                                <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleSmall">
                                    {t('common.header.signUp')}
                                </Text>
                            </CustomLink>
                        </ListItem>
                    </>
                )}

                <ListItem onClick={handleOpenNested}>
                    <ListItemIcon>
                        <LanguagesIcon width="30px" />
                    </ListItemIcon>
                    {openNested ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </ListItem>
                <Collapse in={openNested} timeout="auto" unmountOnExit>
                    <ul>
                        <li style={{ padding: '10px 0', color: '#fff' }} onClick={() => changeLanguage('es')}>
                            <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleSmall">
                                {t('common.header.languages.spanish')}
                            </Text>
                        </li>
                        <li style={{ padding: '10px 0', color: '#fff' }} onClick={() => changeLanguage('en')}>
                            <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleSmall">
                                {t('common.header.languages.english')}
                            </Text>
                        </li>
                    </ul>
                </Collapse>
            </List>
        </Box>
    );
};

const MobileMenu = () => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <CustomButton onClick={handleClick}>
                <MobileMenuIcon />
            </CustomButton>
            <Drawer anchor="right" open={open} onClose={handleClose} PaperProps={{ sx: { backgroundColor: theme.palette.primary.main } }}>
                <MobileMenuList handleClose={handleClose} />
            </Drawer>
        </>
    );
};

function Header() {
    const { t } = useTranslation();
    const theme = useTheme();
    const useInfo = useSelector((state: any) => state.user.userInfo);
    const { isLoggedIn } = useAuth();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const openProfileMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseProfileMenu = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="relative" sx={{ borderRadius: '0 0 10px 10px', zIndex: 10 }}>
            <Toolbar disableGutters>
                <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                    <CustomLink to="/">
                        <BeSoulLogo />
                    </CustomLink>

                    {/* Menu en desktop */}
                    <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        sx={(theme) => ({
                            [theme.breakpoints.up('xs')]: {
                                display: 'none'
                            },
                            [theme.breakpoints.up('md')]: {
                                display: 'flex',
                                width: '100%'
                            },
                            [theme.breakpoints.up('lg')]: {
                                width: '70%'
                            },
                            [`@media (width >= 1960px)`]: {
                                width: '50%'
                            }
                        })}
                    >
                        <CustomLink to={'/digital-legacy'}>
                            <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleLarge">
                                {t('common.header.legacy')}
                            </Text>
                        </CustomLink>
                        <CustomLink to={'/memorials'}>
                            <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleLarge">
                                {t('common.header.memorials')}
                            </Text>
                        </CustomLink>
                        <CustomLink to={'/calm'}>
                            <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleLarge">
                                {t('common.header.calm')}
                            </Text>
                        </CustomLink>
                        <CustomLink to={'/pricing'}>
                            <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleLarge">
                                {t('common.header.plans')}
                            </Text>
                        </CustomLink>
                        {/* <CustomLink to={'#'}>
                            <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleLarge">
                                {t('common.header.about')}
                            </Text>
                        </CustomLink> */}
                        <CustomLink to={'https://blog.besoul.app/'} target="_blank">
                            <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleLarge">
                                {t('common.header.blog')}
                            </Text>
                        </CustomLink>

                        <Divider orientation="vertical" variant="middle" flexItem />

                        {isLoggedIn && useInfo ? (
                            useInfo.profilePhoto ? (
                                <Box>
                                    <CustomButton onClick={openProfileMenu}>
                                        <Box
                                            component="img"
                                            src={useInfo.profilePhoto}
                                            sx={{ borderRadius: '50px', width: '50px', height: '50px', cursor: 'pointer' }}
                                        />
                                        {'&'} {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                    </CustomButton>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleCloseProfileMenu}
                                        MenuListProps={{ sx: { backgroundColor: theme.palette.primary.main } }}
                                    >
                                        <MenuItem onClick={handleCloseProfileMenu}>
                                            <CustomLink to={'/app/memorial/feed'}>
                                                <Text textColor={theme.palette.common.white} preset="titleLarge" fontWeight={700}>
                                                    {t('common.header.memorials')}
                                                </Text>
                                            </CustomLink>
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            ) : (
                                <PersonIcon sx={{ width: '100%', height: 'auto', color: '#fff' }} />
                            )
                        ) : (
                            <>
                                <CustomLink to={'/login'}>
                                    <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleLarge">
                                        {t('common.header.login')}
                                    </Text>
                                </CustomLink>
                                <CustomLink to={'/sign-up'}>
                                    <CustomButton sx={{ xs: { border: '1px solid #FFF' } }}>
                                        <Text textColor={theme.palette.common.white} fontWeight={700} preset="titleLarge">
                                            {t('common.header.signUp')}
                                        </Text>
                                    </CustomButton>
                                </CustomLink>
                            </>
                        )}

                        <Divider orientation="vertical" variant="middle" flexItem />

                        <LanguageMenu />
                    </Stack>

                    {/* Menu en mobile */}
                    <Box
                        sx={(theme) => ({
                            [theme.breakpoints.up('xs')]: {
                                display: 'inline'
                            },
                            [theme.breakpoints.up('md')]: {
                                display: 'none'
                            }
                        })}
                    >
                        <MobileMenu />
                    </Box>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}
export default Header;
