import { useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { ReactComponent as CloseIcon } from '../../../assets/icons/svg/close_icon.svg';
import { useTranslation } from 'react-i18next';
import Text from 'ui-component/Text/Text';
import { useNavigate } from 'react-router-dom';

function Header() {
    const APP_BAR_HEIGHT = '74px';
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <AppBar
            position="relative"
            elevation={0}
            sx={{
                borderBottom: `1px solid ${theme.palette.grey[200]}`,
                backgroundColor: theme.palette.common.white,
                height: APP_BAR_HEIGHT,
                justifyContent: 'center'
            }}
        >
            <Toolbar disableGutters>
                <CloseIcon onClick={() => navigate('/app/memorial/feed')} style={{ cursor: 'pointer' }} />
                <Text preset={'headlineSmall'} fontWeight={500} sx={{ xs: { marginLeft: 2 } }}>
                    {t('memorials.create.title')}
                </Text>
            </Toolbar>
        </AppBar>
    );
}
export default Header;
