const memorial = {
    feed: {
        container: {
            header: {
                headerMyMemorial: 'My memorials',
                headerSigned: 'Signed',
                headerShare: 'Share with me',
                headerExplore: 'Explore',
                rightTextBtn: 'See all'
            },
            item: {
                createdBy: 'Created by:',
                signed: {
                    1: 'Signed {{times}} times',
                    2: 'Signed {{times}} time'
                },
                edit: 'Edit'
            },
            emptyState: {
                myMemorials: {
                    title: 'My memorials',
                    text1: "You haven't created a memorial yet.",
                    text2: 'Create a memorial to honor your loved ones.'
                },
                signed: {
                    title: 'Signed',
                    text1: "You haven't signed any memorials yet",
                    text2: 'Sign a memorial to show your support'
                },
                shared: {
                    title: 'Share with me',
                    text1: "You haven't shared any memorials yet",
                    text2: 'Share a memorial to show your support'
                }
            }
        },
        sections: {
            page: {
                myMemorials: {
                    title: 'My memorials'
                },
                signed: {
                    title: 'Signed'
                },
                shared: {
                    title: 'Shared'
                },
                explore: {
                    title: 'Explore'
                }
            },
            filter: {
                placeholder: 'Search',
                placeholder2: 'Search a memorial',
                all: 'All',
                people: 'People',
                pets: 'Pets',
                drafts: 'Drafts'
            }
        }
    },
    view: {
        tabMemorial: 'Memorial',
        tabGallery: 'Gallery',
        inMemoryOf: 'In memory of',
        share: {
            download: 'Download',
            shareWith: 'Share memorial',
            copy: 'Copy link',
            text: 'Visit this memorial in Be soul'
        }
    }
};

export default memorial;
