import { Box, CircularProgress } from '@mui/material';
import BesoulLogo from '../../assets/icons/svg/besoul_logo.svg';

const SplashScreen = () => {
    return (
        <>
            <div className="kt-splash-screen" style={{ height: '100vh' }}>
                <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Box sx={{ backgroundColor: '#94c7d8', padding: '5px auto', borderRadius: 10, marginBottom: 20 }}>
                        <Box component="img" src={BesoulLogo} width={{ xs: '200px', md: '300px' }} />
                    </Box>
                    <CircularProgress />
                </Box>
            </div>
        </>
    );
};

export default SplashScreen;
