const termsConditions = {
    seo: {
        title: 'Términos y condiciones',
        description: 'Dummy description for terms and conditions section'
    },
    title: 'Términos y condiciones',
    date: ''
};

export default termsConditions;
