const calm = {
    seo: {
        title: 'A Healing Space for Grief and Serenity | Be Soul Calm',
        description:
            'Explore our Calm Tools for soothing grief support and end-of-life. Discover a comforting space on our app, designed to bring tranquility during challenging times.'
    },
    banner: {
        title: 'Calm',
        subtitle: 'We take care of your emotional and mental well-being',
        label: 'Coming soon'
    },
    info1: {
        title: 'Tools to manage discomfort',
        item: 'Discover techniques and tools to regain emotional balance'
    },
    info2: {
        title: 'Relaxation exercises',
        item: 'Reduce stress and anxiety in your daily life'
    },
    info3: {
        title: 'Exercises to increase well-being',
        item: 'Cultivate daily gratitude and nourish your inner happiness'
    },
    button: 'Download Be Soul'
};

export default calm;
