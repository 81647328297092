import { Outlet } from 'react-router-dom';

// material-ui
import { Box, CssBaseline, styled } from '@mui/material';

// project imports
import Modal from 'components/Modal';
// import useAuth from 'hooks/useAuth';
import useScreenSize from 'hooks/useScreenSize';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectMemorialType from 'views/pages/CreateMemorial/Components/SelectMemorialType';
import AuthHeader from './AuthHeader';
import Footer from './Footer';
import Header from './Header';
import useLocalStorage from 'hooks/useLocalStorage';

// ==============================|| MAIN LAYOUT ||============================== //

const drawerWidth = 300;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
    enabledraweranimation: string;
}>(({ theme, open, enabledraweranimation }) => ({
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: 0,
    ...(open &&
        enabledraweranimation === 'true' && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }),
            marginLeft: `${drawerWidth}px`
        }),
    height: 'calc(100vh - 73px)'
}));

const MainLayout = ({ isLanding = false }: { isLanding?: boolean }) => {
    const { isDesktop } = useScreenSize();
    const [isDrawerOpen] = useLocalStorage('isDrawerOpen', false);
    const [openAuthDrawer, setOpenAuthDrawer] = useState<boolean>(!isDesktop ? false : isDrawerOpen);
    const [openCreateMemorialModal, setOpenCreateMemorialModal] = useState(false);
    const { t } = useTranslation();
    // const { isLoggedIn } = useAuth();

    return (
        <Box>
            <Modal
                title={t('memorials.create.selectTypeTitle')}
                openModal={openCreateMemorialModal}
                setOpenModal={setOpenCreateMemorialModal}
                children={<SelectMemorialType />}
            />
            <CssBaseline />
            {/* header */}
            {isLanding ? (
                <Header />
            ) : (
                <AuthHeader
                    openAuthDrawer={openAuthDrawer}
                    setOpenAuthDrawer={setOpenAuthDrawer}
                    setOpenModal={setOpenCreateMemorialModal}
                />
            )}
            <Box marginTop="-15px">
                {isLanding ? (
                    <>
                        <Outlet />
                        <Footer />
                    </>
                ) : (
                    <Main open={openAuthDrawer} enabledraweranimation={!isDesktop ? 'false' : 'true'}>
                        <Outlet /> {/*Renders the child route's element, if there is one.*/}
                        {/* <Footer /> */}
                    </Main>
                )}
            </Box>
            {/* footer */}
        </Box>
    );
};

export default MainLayout;
