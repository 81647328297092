import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { FirebaseConfig } from './types';
import { ReCaptchaEnterpriseProvider, initializeAppCheck } from 'firebase/app-check';
import { TENANT_ID } from 'environment';

export const firebaseConfig: FirebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_BESOUL_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const app = initializeApp(firebaseConfig);

if (process.env?.REACT_APP_NODE_ENV === 'local') {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_DEBUG_TOKEN;
}

export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAP_KEY ?? ''),
    isTokenAutoRefreshEnabled: true
    // Otras opciones de configuración de seguridad aquí
});
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
export const perf = getPerformance(app);
const auth = getAuth(app);
auth.tenantId = TENANT_ID;

export { auth };
