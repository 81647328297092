const plans = {
    seo: {
        title: 'Plans & Pricing | Be Soul',
        description: `Unlock premium benefits with Be Soul's subscription plans. Share memories, stories, and build a meaningful digital legacy. Choose your plan and start today`
    },
    banner: {
        title: {
            '1': 'Our ',
            '2': 'Plans'
        },
        subtitle: 'Preserve your legacy at Be Soul'
    },
    title: 'Plans',
    plans: {
        month: ' / Month',
        yearly: 'annually',
        basic: {
            name: 'Basic',
            features: {
                '1': '10 Legacies',
                '2': '220 Access to Legacies',
                '3': '3,000 photos',
                '4': '30 minutes of video',
                '5': '2,076 minutes of audio',
                '6': 'Download Legacies multimedia'
            }
        },
        standard: {
            name: 'Standard',
            features: {
                '1': '25 Legacies',
                '2': '50 Access to Legacies',
                '3': '12,000 photos',
                '4': '122 minutes of video',
                '5': '8,302 minutes of audio',
                '6': 'Download Legacies multimedia'
            }
        },
        premium: {
            name: 'Premium',
            features: {
                '1': '100 Legacies',
                '2': '100 Access to Legacies',
                '3': '384,000 photos',
                '4': '3,904 minutes of video',
                '5': '256,672 minutes of audio',
                '6': 'Download Legacies multimedia'
            }
        },
        recommended: 'Recommended'
    },
    warranty:
        'All plans have a 1-month trial. No charges will be made during that period. The plan renews automatically unless you cancel it up to one day before its end',
    TyC: { 1: 'For more information you can consult the ', 2: 'Terms and Conditions', 3: ' of Be Soul' },
    button: 'Start now',
    tooltip: {
        1: '1 Megabyte (MB) = 1,000,000 bytes; 1 Gigabyte (GB) = 1,000,000,000,000 bytes; 1 Terabyte (TB) = 1,000,000,000,000,000 bytes',
        2: 'The storage is total and the amount in number indicated for each format of audiovisual content is referential, and may vary depending on the camera model, selected resolution, compression settings and content'
    }
};

export default plans;
