import { SHA512, enc } from 'crypto-js';

interface GeneratePayloadSignatureProps {
    xTimestamp?: string;
    url?: string;
    method?: string;
    body?: any;
    searchParams?: any;
}

export const generatePayloadSignature = ({
    xTimestamp,
    url = '',
    method = '',
    body = {},
    searchParams = {}
}: GeneratePayloadSignatureProps) => {
    const hashData = SHA512(JSON.stringify(body) + JSON.stringify(searchParams)).toString(enc.Hex);

    const xCrypt = SHA512(`${xTimestamp}${url}${hashData}${method}Web`);

    return xCrypt.toString(enc.Hex);
};

/*
Construir la firma del payload concatenando los siguientes valores:
sha512[ x-timestamp + url + sha512[ JSON.stringify(body || {}) + JSON.stingify(searchParams || {}) ] + method + x-app-sign ]
La funcion que construye esta firma es la funcion que van a ofuscar con la herramienta de ofuscacion de codigo.
La idea es que a traves de ingenieria inversa sea un poco mas dificil que den con la funcion que hace el calculo.

 */
