const createMemorialAssist = {
    nextStep: 'Next',
    prevStep: 'Previous',
    createMemorial: 'Create Memorial',
    addPhoto: {
        title: 'Identify the memorial',
        subtitle: 'Upload a photo to identify the memorial',
        button: 'Upload photo'
    },
    addName: {
        title: "What was that person's name?",
        placeholder: 'Name and Surname'
    },
    addPetName: {
        title: "What was your pet's name?",
        placeholder: 'Name'
    },
    addBirthPlace: {
        title: 'Where was he/she born?',
        subtitle: 'Ex: Chicago, Illinois, United States',
        placeholder: 'State/City, Country'
    },
    addPetBirthPlace: {
        title: 'Where was it born?',
        subtitle: 'Ex: Chicago, Illinois, United States',
        placeholder: 'State/City, Country'
    },
    addBirthDate: {
        title: 'When was he/she born?',
        subtitle: 'Then select a date'
    },
    addPetBirthDate: {
        title: 'When was it born?',
        subtitle: 'Then select a date'
    },
    addDeathPlace: {
        title: 'Where did he/she pass away?',
        subtitle: 'Ex: Chicago, Illinois, United States',
        placeholder: 'State/City, Country'
    },
    addPetDeathPlace: {
        title: 'Where did it pass away?',
        subtitle: 'Ex: Chicago, Illinois, United States',
        placeholder: 'State/City, Country'
    },
    addDeathDate: {
        title: 'When did he/she pass away?',
        subtitle: 'Then select a date'
    },
    addPetDeathDate: {
        title: 'When did it pass away?',
        subtitle: 'Then select a date'
    },
    addOccupation: {
        title: 'What was his/her occupation and professional achievements?',
        subtitle: 'Highlight any significant achievements in his/her career.',
        placeholder: 'Start writing here'
    },
    addHobbies: {
        title: 'What hobbies or interests did he/she have?',
        subtitle: 'Talk about the activities he/she enjoyed in his/her spare time.',
        placeholder: 'Start writing here'
    },
    addContributionsToCommunity: {
        title: 'What was his/her contributions to the community?',
        subtitle: 'Highlight any community or philanthropic service.',
        placeholder: 'Start writing here'
    },
    addPersonalStory: {
        title: 'Is there a personal anecdote or story that captures his/her spirit?',
        subtitle: 'Share stories that show his/her personality and character.',
        placeholder: 'Start writing here'
    },
    addKindOfPet: {
        title: '{{petName}} was a...',
        subtitle: 'What kind of pet was {{petName}}, a dog, a cat, a reptile, a bird, an insect?',
        placeholder: 'Start writing here'
    },
    addHowDidYouMeet: {
        title: 'How did you meet?',
        subtitle:
            "Highlight how he came to be a part of your life, where you found or adopted him, what {{petName}}'s unique characteristics were, both physical and personality.",
        placeholder: 'Start writing here'
    },
    addSharedMoments: {
        title: 'Shared moments...',
        subtitle:
            'What {{petName}} favorite activities or games were, highlight any particularly funny or touching stories or anecdotes about it.',
        placeholder: 'Start writing here'
    },
    addHowYourLifeChange: {
        title: 'How did your life change?',
        subtitle:
            'How did it positively affect your life and the lives of the people who were close to {{petName}}? Were there any lessons or teachings that {{petName}} left for those around him?',
        placeholder: 'Start writing here'
    },
    addRememberForever: {
        title: 'Remember forever',
        subtitle:
            'How would you like {{petName}} to be remembered and honored? Is there any image, object or place that is especially associated with it?',
        placeholder: 'Start writing here'
    },
    loadingCreation: {
        title: 'Creating memorial...',
        subtitle: 'We are creating your memorial'
    },
    result: {
        button1: 'Use this text',
        button2: 'Regenerate',
        modal: {
            title: 'Memorial created',
            subtitle: 'Remember that artificial intelligence is not infallible. You can modify this text according to your preferences.',
            button: 'Got it'
        },
        regenerateButton: {
            edit: 'Edit answers',
            regenerate: 'Generate new version',
            cancel: 'Cancel'
        }
    }
};
export default createMemorialAssist;
