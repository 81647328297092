const support = {
    seo: {
        title: 'Support | Be Soul',
        description: 'Find answers to common questions, troubleshooting guides, and connect with our dedicated support team.'
    },
    title: 'Support',
    subtitle:
        'At Be Soul, we strive to provide the best care. We would appreciate it if you would share your concerns with us so we can continue to improve',
    form: {
        input1: {
            name: 'Your name',
            placeholder: 'Name'
        },
        radioButton: {
            1: 'Via email',
            2: 'Via mobile'
        },
        input2: {
            name: 'Your email',
            placeholder: 'Email'
        },
        input3: {
            name: 'Your phone',
            placeholder: 'Phone'
        },
        textArea: {
            name: 'Leave us a message',
            placeholder: 'Write your concerns here'
        },
        submitButton: 'Send'
    },
    success: {
        1: 'We have received your concern and are working to address any other concerns you may have.',
        2: 'Thanks for trusting us!',
        button: 'Back to Home'
    }
};

export default support;
