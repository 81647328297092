const calm = {
    seo: {
        title: 'Un espacio de sanación para el duelo y la serenidad | Estar en calma del alma',
        description:
            'Explore nuestras Herramientas de Calma para aliviar el duelo y el final de la vida. Descubre un espacio reconfortante en nuestra app, diseñado para aportar tranquilidad en momentos difíciles.'
    },
    banner: {
        title: 'Calma',
        subtitle: 'Cuidamos tu bienestar emocional y mental',
        label: 'Próximamente'
    },
    info1: {
        title: 'Herramientas para gestionar el malestar',
        item: 'Descubre técnicas y herramientas para recuperar el equilibrio emocional'
    },
    info2: {
        title: 'Ejercicios de relajación',
        item: 'Reduce el estrés y la ansiedad en tu vida cotidiana'
    },
    info3: {
        title: 'Ejercicios para aumentar el bienestar',
        item: 'Cultiva la gratitud diaria y nutre tu felicidad interior'
    },
    button: 'Descarga Be Soul'
};

export default calm;
