const createMemorialAssist = {
    nextStep: 'Siguiente',
    prevStep: 'Anterior',
    createMemorial: 'Crear Memorial',
    addPhoto: {
        title: ' Identifica el memorial',
        subtitle: 'Sube una foto para identificar el memorial',
        button: 'Subir foto'
    },
    addName: {
        title: '¿Cómo se llamaba esa persona?',
        placeholder: 'Nombre y Apellido'
    },
    addPetName: {
        title: '¿Cómo se llamaba tu mascota?',
        placeholder: 'Nombre'
    },
    addBirthPlace: {
        title: '¿Dónde nació?',
        subtitle: 'Ej: Chicago, Illinois, Estados Unidos',
        placeholder: 'Estado/Ciudad, País'
    },
    addPetBirthPlace: {
        title: '¿Dónde nació?',
        subtitle: 'Ej: Chicago, Illinois, United States',
        placeholder: 'Estado/Ciudad, País'
    },
    addBirthDate: {
        title: '¿Cuándo nació?',
        subtitle: 'Ahora selecciona una fecha'
    },
    addPetBirthDate: {
        title: '¿Cuándo nació?',
        subtitle: 'Ahora selecciona una fecha'
    },
    addDeathPlace: {
        title: '¿Dónde pasó a mejor vida?',
        subtitle: 'Ej: Chicago, Illinois, Estados Unidos',
        placeholder: 'Estado/Ciudad, País'
    },
    addPetDeathPlace: {
        title: '¿Dónde pasó a mejor vida?',
        subtitle: 'Ej: Chicago, Illinois, United States',
        placeholder: 'Estado/Ciudad, País'
    },
    addDeathDate: {
        title: '¿Cuándo pasó a mejor vida?',
        subtitle: 'Ahora selecciona una fecha'
    },
    addPetDeathDate: {
        title: '¿Cuándo pasó a mejor vida?',
        subtitle: 'Ahora selecciona una fecha'
    },
    addOccupation: {
        title: '¿Cuál era su ocupación y logros profesionales?',
        subtitle: 'Destaca cualquier logro significativo en su carrera.',
        placeholder: 'Empieza a escribir aquí'
    },
    addHobbies: {
        title: '¿Qué pasatiempos o intereses tenía?',
        subtitle: 'Habla sobre las actividades que disfrutaba en su tiempo libre.',
        placeholder: 'Empieza a escribir aquí'
    },
    addContributionsToCommunity: {
        title: '¿Cuáles fueron sus contribuciones a la comunidad?',
        subtitle: 'Resalta cualquier servicio comunitario o filantrópico.',
        placeholder: 'Empieza a escribir aquí'
    },
    addPersonalStory: {
        title: '¿Hay alguna anécdota o historia personal que capture su espíritu?',
        subtitle: '¿Hay alguna anécdota o historia personal que capture su espíritu?',
        placeholder: 'Empieza a escribir aquí'
    },
    addKindOfPet: {
        title: '{{petName}} era un...',
        subtitle: '¿Qué tipo de mascota era {{petName}}, un perro, un gato, un reptil, un ave, un insecto?',
        placeholder: 'Empieza a escribir aquí'
    },
    addHowDidYouMeet: {
        title: '¿Cómo se conocieron?',
        subtitle:
            'Destaca cómo llegó a formar parte de tu vida, dónde encontraste o adoptaste, cuáles eran las características únicas de {{petName}}, tanto físicas como de personalidad.',
        placeholder: 'Empieza a escribir aquí'
    },
    addSharedMoments: {
        title: 'Momentos compartidos...',
        subtitle:
            'Cuáles eran las actividades o juegos favoritos de {{petName}}, resalta cualquier historia o anécdota particularmente divertida o conmovedora sobre {{petName}}..',
        placeholder: 'Empieza a escribir aquí'
    },
    addHowYourLifeChange: {
        title: '¿Cómo cambió tu vida?',
        subtitle:
            '¿Cómo afectó positivamente tu vida y la de las personas que estuvieron cerca de {{petName}}? ¿Hubo alguna lección o enseñanza que {{petName}} dejó a quienes le rodeaban? ',
        placeholder: 'Empieza a escribir aquí'
    },
    addRememberForever: {
        title: 'Recordar por siempre',
        subtitle:
            '¿Cómo te gustaría que {{petName}} sea recordado y honrado? ¿Hay alguna imagen, objeto o lugar que esté asociado de manera especial con {{petName}}?',
        placeholder: 'Empieza a escribir aquí'
    },
    loadingCreation: {
        title: 'Creando memorial...',
        subtitle: 'Estamos creando tu memorial'
    },
    result: {
        button1: 'Usar este texto',
        button2: 'Regenerar',
        modal: {
            title: 'Memorial creado',
            subtitle: 'Recuerda que la inteligencia artificial no es infalible. Puedes modificar este texto según tus preferencias.',
            button: 'Entendido'
        },
        regenerateButton: {
            edit: 'Editar mis respuestas',
            regenerate: 'Generar una nueva versión',
            cancel: 'Cancelar'
        }
    }
};
export default createMemorialAssist;
