const signUp = {
    title: 'A life to be remembered',
    formTitle: 'Create account',
    form1: {
        title: 'We want to know you better',
        subtitle: {
            1: 'Enter your ',
            2: 'Names & Surnames'
        },
        input1: {
            label: 'Names',
            placeholder: ''
        },
        input2: {
            label: 'Surnames',
            placeholder: ''
        },
        button: 'Continue'
    },
    form2: {
        title: 'Do you want to share your phone number?',
        subtitle: {
            1: 'This step is completely ',
            2: 'optional'
        },
        input1: {
            label: 'Phone number',
            placeholder: ''
        },
        button: 'Continue'
    },
    signUpWithGoogle: 'Sign Up with Google',
    signUpWithApple: 'Sign Up with Apple',
    userAlreadyRegister: {
        title: 'There is already an account associated with the email',
        subtitle: 'Do you want to log in with this email?',
        button: {
            1: 'Log in',
            2: 'Try another email'
        }
    },
    haveAnAccount: {
        1: 'Already have an account?',
        2: 'Log in'
    },
    seo: {
        title: 'Be Soul: Legacy planning app'
    }
};

export default signUp;
